<script setup lang="ts">
import { ShareProps } from "./Share.props"

defineProps<ShareProps>()

const { openModal, closeModal } = useModal()
</script>

<template>
  <div @click="openModal('share')">
    <div class="check-email-modal-wrapper">
      <DialogsAndModalsUtilsModal id="share" class="h-screen w-screen">
        <DialogsAndModalsShare
          :is-product="isProduct"
          @on-close="closeModal('share')"
        />
      </DialogsAndModalsUtilsModal>
    </div>
    <slot></slot>
  </div>
</template>
