<script setup lang="ts">
import { url } from "inspector"
import { shareIconsSrcMap } from "./Share.iconMapping"
import { ShareProps } from "./Share.props"
import { SEmail, SWhatsApp, SFacebook, STwitter, SPinterest } from "vue-socials"

const props = defineProps<ShareProps>()

const { t } = useI18n()

const ctaButton = ref(
  props.isProduct
    ? t("dialogsAndModals.share.ctaButtonProduct")
    : t("dialogsAndModals.share.ctaButton")
)

const copyLink = () => {
  ctaButton.value = t("dialogsAndModals.share.linkCopy")

  setTimeout(
    () =>
      (ctaButton.value = props.isProduct
        ? t("dialogsAndModals.share.ctaButtonProduct")
        : t("dialogsAndModals.share.ctaButton")),
    3000
  )

  const url = window.location.href
  navigator.clipboard.writeText(url)
}

defineEmits<{
  (e: "onShare", value: any): void
  (e: "onClose"): void
}>()

const sharedUrl = window?.location?.href?.toString() ?? "#"

const useNativeBehavior = ref(true)

const shareOptionsEmail = ref({
  mail: "",
  cc: ["", ""],
  bcc: ["", ""],
  subject: "",
  body: sharedUrl
})
const shareOptionsFacebook = ref({
  url: sharedUrl,
  quote: "",
  hashtag: "#topFarmacia"
})
const shareOptionsWhatsapp = ref({
  number: "",
  text: sharedUrl
})
const shareOptionsTwitter = ref({
  url: sharedUrl,
  text: ""
})
const shareOptionsPinterest = ref({
  url: sharedUrl,
  media: "",
  description: "",
  hashtags: ["topFarmacia"],
  via: ""
})
</script>

<template>
  <DialogsAndModalsStandard
    :title="
      props.isProduct
        ? t('dialogsAndModals.share.shareProduct')
        : t('dialogsAndModals.share.share')
    "
    @on-close="$emit('onClose')"
  >
    <template #content>
      <div class="flex flex-col items-center gap-4 bg-white px-4 pb-6 pt-10">
        <div class="flex gap-3">
          <s-email :shareOptions="shareOptionsEmail">
            <UtilsIcon
              v-bind="shareIconsSrcMap.shareEmail"
              class="share-icon"
            />
          </s-email>

          <s-facebook
            :shareOptions="shareOptionsFacebook"
            :use-native-behavior="useNativeBehavior"
          >
            <UtilsIcon
              v-bind="shareIconsSrcMap.shareFacebook"
              class="share-icon"
            />
          </s-facebook>

          <s-whats-app
            :shareOptions="shareOptionsWhatsapp"
            :use-native-behavior="useNativeBehavior"
          >
            <UtilsIcon
              v-bind="shareIconsSrcMap.shareWhatsapp"
              class="share-icon"
            />
          </s-whats-app>

          <s-twitter
            :shareOptions="shareOptionsTwitter"
            :use-native-behavior="useNativeBehavior"
          >
            <UtilsIcon
              v-bind="shareIconsSrcMap.shareTwitter"
              class="share-icon"
            />
          </s-twitter>

          <s-pinterest
            :shareOptions="shareOptionsPinterest"
            :use-native-behavior="useNativeBehavior"
          >
            <UtilsIcon
              v-bind="shareIconsSrcMap.sharePinterest"
              class="share-icon"
            />
          </s-pinterest>
        </div>

        <span class="beaver block">
          {{ t("dialogsAndModals.share.or") }}
        </span>

        <UtilsButton
          v-if="ctaButton"
          @click="copyLink"
          class="w-full flex-1"
          :text="ctaButton"
        />
      </div>
    </template>
  </DialogsAndModalsStandard>
</template>

<style scoped lang="scss">

.share-icon {
  @apply h-8 w-8 cursor-pointer hover:opacity-80 md:h-10 md:w-10;
}

</style>
